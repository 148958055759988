import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const BimCdeAdoption: React.FC = () => {
  return (
    <>
      <SEO
        title="BIM CDE Adoption"
        description="Implementation and BIM consulting are big parts of  SIGNAX business. Customers experience is shared in this article."
      />
      <NewsSectionBlock date="08.09.2023">
        <Typography variant="h1" color="blue">
          BIM and Сommon Data Environment adoption
        </Typography>
        <Typography variant="h2" color="blue">
          BIM Implementation in SEVERIN DEVELOPMENT
        </Typography>
        <Typography variant="h3">
          Improving Engineering Consultant Efficiency Using Digital Technologies
        </Typography>
        <Typography variant="body1">
          SEVERIN DEVELOPMENT specializes in providing Engineering Consultancy
          services to various Real Estate developers. The company also has
          divisions for Industrial facility design and construction.
        </Typography>
        <Typography variant="body1">
          SEVERIN DEVELOPMENT's management set a goal to implement BIM during
          the design and construction phases, as well as for the role of Lead
          Consultant. The key metrics expected to be achieved were increased
          quality and speed of design and construction, both when working
          autonomously and while providing services to clients.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-1.png"
            alt="BIM management process"
            title="BIM management process"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.1 BIM management process
          </Typography>
        </div>
        <Typography variant="body1">
          The EMEA division of SIGNAX team (Autodesk Authorized Service
          Provider) was responsible for the implementation of BIM in the company
          and training employees to work with modern software products.
        </Typography>
        <Typography variant="body1">
          Initially, a roadmap was developed, and during the discussions, it
          became apparent that besides BIM, the implementation of a Common Data
          Environment (CDE) was also necessary. Additionally, an internal
          classifier had to be developed, and BIM standards, along with template
          EIR (Employer's Information Requirements) and BEP (BIM Execution
          Plan), needed to be created.
        </Typography>
        <Typography variant="body1">
          As a platform for organizing BIM within the company, the Autodesk
          platform was selected. Designers were trained to work with Autodesk
          Revit, and an internal BIM standard, family libraries, and Dynamo
          scripts were developed for them to expedite the design processes.
        </Typography>
        <Typography variant="body1">
          The development of the construction elements classifier was based on
          the 2010 edition of Uniformat, with additional extensions made at
          levels 4 and 5. Automation plugins were developed to fill the
          classifier codes into the elements of the BIM model.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-2.png"
            alt="Classification system"
            title="Classification system"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.2 Classification system
          </Typography>
        </div>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-3.png"
            alt="SIGNAX TOOLS for BIM model classification"
            title="SIGNAX TOOLS for BIM model classification"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.3 SIGNAX TOOLS for BIM model classification
          </Typography>
        </div>
        <Typography variant="body1">
          During the implementation process, the lack of default Revit tools for
          construction monitoring and control became evident. As a result,
          additional plugins were developed to manage the construction BIM
          model, segment it as the work progressed, and populate it with work
          completion dates and construction status. Furthermore, enhancements
          were made to link documents and annotations from the Common Data
          Environment to model elements.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-4.png"
            alt="CDE for QA/QC workflows"
            title="CDE for QA/QC workflows"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.4 CDE for QA/QC workflows
          </Typography>
        </div>
        <Typography variant="body1">
          The BIM 360 Docs system (ACC Docs now) was chosen as the Common Data
          Environment, as it was the most promising and user-friendly option at
          that time. Moreover, it integrated well with the Autodesk ecosystem.
          On one project, the BIM 360 Design solution was implemented for
          organizing collaborative design with cloud publishing. However, for
          scaling BIM across the entire company, it was decided that Design was
          too expensive. As a more cost-effective alternative, the solution
          proposed was to use Revit Server + Navisworks.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-5.jpg"
            alt="BIM model version comparison"
            title="BIM model version comparison"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.5 BIM model version comparison
          </Typography>
        </div>
        <Typography variant="body1">
          To facilitate work in BIM 360 Docs, an Interaction Protocol for users
          was developed; folder structures, access rights based on role-based
          permissions, issue types, and approval processes were configured in
          the system. Users noted the significant convenience of working in the
          system for document review and drawing approvals.
        </Typography>
        <Typography variant="body1">
          Training sessions were also conducted on working with BIM models in
          the system, such as comparing versions and setting up
          issues/annotations.
        </Typography>
        <Typography variant="body1">
          During the implementation, various user needs for process automation
          arose, and thanks to SIGNAX having its own developers, these
          requirements were quickly addressed. Solutions were developed for
          backing up files from BIM360 to the Client's internal servers,
          exporting issues as separate legally significant documents, and
          digitally signing issued for construction documents (to ensure
          paperless construction in full compliance with regional regulations).
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-6.png"
            alt="SIGNAX Backup utility for Autodesk CDE system"
            title="SIGNAX Backup utility for Autodesk CDE system"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.6 SIGNAX Backup utility for Autodesk CDE system
          </Typography>
        </div>
        <Typography variant="body1">
          Using this implementation project as an example, the SIGNAX team
          demonstrated its competence in understanding BIM technology,
          development competence, and the ability to adapt international
          software solutions to the specifics of regional business. SEVERIN
          DEVELOPMENT noted the increase of design effectiveness by{' '}
          <strong>30%</strong>, and construction effectiveness on site by{' '}
          <strong>10%</strong> due to the project.
        </Typography>
        <Typography variant="h2" color="blue">
          BIM implementation in River Development
        </Typography>
        <Typography variant="h3">
          Digital construction processes establishment on construction site
        </Typography>
        <Typography variant="body1">
          River Development Company is a major Real Estate Developer
          specializing in the construction of comfort and premium-class
          residential buildings.
        </Typography>
        <Typography variant="body1">
          SIGNAX specialists participated in consulting the Client and all
          construction participants on the implementation of BIM during the
          construction phase.
        </Typography>
        <Typography variant="body1">
          The first project where photogrammetry, laser scanning, and BIM
          modeling technologies were applied was the “T-Tower”.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/preview.png"
            alt="T-Tower project. All disciplines were made in BIM."
            title="T-Tower project. All disciplines were made in BIM."
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.7 T-Tower project. All disciplines were made in BIM
          </Typography>
        </div>
        <Typography variant="body1">
          The SIGNAX team performed model validation received from the designer,
          checked for clashes, verified parameter completion in the model, and
          issued remarks. Additionally, to facilitate interaction with the
          developer of the BIM model, they set up operations through BIM 360
          Docs Common Data Environment to control clash resolution.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={496}
            src="../assets/images/news/bim-cde-adoption/image-7.png"
            alt="BIM model management"
            title="BIM model management"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.8 BIM model management
          </Typography>
        </div>
        <Typography variant="body1">
          After resolving significant clashes and element duplications, all work
          volumes were extracted from the model, enabling transparent and clear
          resolution of financial matters after changes in design decisions
          during construction.
        </Typography>
        <Typography variant="body1">
          The approach of combining point cloud data from drone surveys with the
          4D planned model was also used to display construction progress
          deviations from planned schedules.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-8.jpg"
            alt="4D BIM model development"
            title="4D BIM model development"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.9 4D BIM model development
          </Typography>
        </div>
        <Typography variant="body1">
          Anton Snyatkov, the project manager from the Engineering Consultant's
          side, characterized the results of the BIM model volume closure
          implementation as follows:
        </Typography>
        <Typography variant="body1">
          "At weekly meetings, the issue of how much work was completed and
          agreed upon for payment in the past month completely disappeared from
          the agenda. What used to cause numerous disputes and disagreements
          simply vanished. All process participants now have a clear
          understanding of the situation, and it has become more transparent for
          everyone. What used to take months for agreement now gets resolved
          within a week."
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-9.png"
            alt="Laser scanner data applied for BIM modeling process"
            title="Laser scanner data applied for BIM modeling process"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.10 Laser scanner data applied for BIM modeling process
          </Typography>
        </div>
        <Typography variant="body1">
          The next project to implement BIM on the construction site was the
          third phase of the “Heart of the Capital” project. Here, besides the
          approaches practiced and implemented at the "T-Tower" project, linking
          remarks to model elements through BIM 360 Docs was also implemented.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-10.jpg"
            alt="Construction issue management on BIM model"
            title="Construction issue management on BIM model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.11 Construction issue management on BIM model
          </Typography>
        </div>
        <Typography variant="h2" color="blue">
          BIM at Emer Group
        </Typography>
        <Typography variant="h3">
          Scaling the unified BIM methodology to all projects of the company
        </Typography>
        <Typography variant="body1">
          Emer Group is a major developer of comfort and premium-class
          residential properties. BIM implementation in Emer Group began with
          the development of their BIM standard and issuing requirements to
          their designers. In 2020-2022, the company actively started using BIM
          360 Docs for drawing approvals to transfer them to the construction
          site.
        </Typography>
        <Typography variant="body1">
          SIGNAX participated in the BIM implementation on the construction
          site, including the customization of BIM 360 Docs to ensure electronic
          documents comply with regional regulations and eliminate the need for
          printing on paper. With SIGNAX having its own software developers, the
          solution was tailored to meet the required functionality, ensuring a
          completely paperless construction process.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/news/bim-cde-adoption/image-11.png"
            alt="Digital “Issued for Construction” documentation approval with QR code"
            title="Digital “Issued for Construction” documentation approval with QR code"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.12 Digital “Issued for Construction” documentation approval with
            QR code
          </Typography>
        </div>
        <Typography variant="body1">
          Next, SIGNAX specialists provided training to QA/QC engineers and
          general contractor experts on using BIM 360 Docs. Processes for
          issuing and resolving work orders, as well as uploading, approving,
          and signing as-built documentation, were set up. QA/QC and scope
          specialists were also trained in creating the construction model using
          Autodesk Revit, Navisworks, and own SIGNAX TOOLS module, as well as
          performing photo documentation and updating the customer's personal
          account.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={496}
            src="../assets/images/news/bim-cde-adoption/image-12.jpg"
            alt="Construction BIM model"
            title="Construction BIM model"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.13 Construction BIM model
          </Typography>
        </div>
        <Typography variant="body1">
          One of the crucial stages of BIM implementation in Emer Group was the
          pilot application of IT solutions on three different construction
          sites with various contracting companies. This verification aimed to
          demonstrate the stability of the chosen technologies and their
          applicability to any company (not just the most advanced ones). All
          specialists on the three sites were trained within one month, followed
          by one more month of technical support. Although three months were
          allocated for the pilot project, it was already recognized as
          successful after two months, and the approaches and solutions were
          adopted as the company's standard operating procedures.
        </Typography>
        <Typography variant="body1">
          SIGNAX assisted the developer in customizing their classifier and EIR
          to meet construction requirements and trained specialists on the site
          to apply modern technologies in practice. Additionally, some plugins
          and add-ons were developed for Autodesk products, allowing their
          solutions to be adapted for construction and regional requirements.
        </Typography>
        <Typography variant="body1">
          SIGNAX services have allowed us to shorten the time of inspections and
          site work acceptance by <strong>2 times</strong>, and the number of
          construction site visits has decreased by <strong>4 times</strong>.
        </Typography>
        <Typography variant="body1">
          "Thanks to Signax and the Autodesk Docs common data environment, all
          construction participants (contractors, designers, consultants) have
          unified and transparent information on any mobile device online. It
          allows us to follow the project progress and be sure of the final
          result" - summed up Emer Group CEO Alex Obukhov.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimCdeAdoption
